import React from "react";
import CategoryItem from "../components/CategoryItem";
import categories from "../data/categories.json"

function Products() {
  //const categories = ["Ropa", "Guantes", "Calzado", "Protector facial", "Proteccion craneal", "Proteccion ocular", "Proteccion auditiva", "Proteccion respiratoria", "Herramientas", "Emergencias", "Proteccion en altura", "Otros"]
  return (
    <>
      
      <div className="container col align-items-center justify-content-center">

        <div className="row mt-3 align-items-center justify-content-center">
          {
            categories.map(category => (
              <CategoryItem key={category} category={category} />
            ))
          }
        </div>
      </div>
    </>
  );
}

export default Products;
