import React from 'react';
import image from '../assets/images/logo-b.png';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

function NavBar() {
    return (
        <nav className="navbar navbar-expand-lg navbar-dark " style={{backgroundColor:"#318CE7"}}>
            <div className="container-fluid">
                <Link className="navbar-brand" to="/">
                    <div className="sidebar-brand-icon text-center">
                        <img className="" src={image} alt="eppinsumosarg" style={{ width: "60px" }} />
                    </div>
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse " id="navbarNavDropdown">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item py-3 mx-3">
                            <Link className="nav-link" to="/">
                                <span>Productos</span>
                            </Link>
                        </li>
                        <li className="nav-item py-3 mx-3">
                            <Link className="nav-link" to="/Us">
                                <span>Nosotros</span></Link>
                        </li>
                        <li className="nav-item py-3 mx-3">
                            <Link className="nav-link" to="/Contact">
                                <span>Contactanos</span>
                            </Link>
                        </li>
                    </ul>
                    {/* <form className="d-flex">
                        <input className="form-control mr-sm-2" type="search" placeholder="Buscar" aria-label="Search" />
                        <button className="btn btn-outline-light my-2 my-sm-0" type="submit">Buscar</button>
                    </form> */}
                </div>
            </div>
        </nav>
    )
}
export default NavBar;