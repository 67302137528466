import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";
import image from "../assets/images/epps.jpg";

function CategoryItem ({category}) {
    return(
        <div className="card border-left- shadow h-100 py-2 mx-3 my-3 col-xs-12 col-sm-3">
          <Link
            className="navbar-brand"
            to={{
              pathname: "/ProductsByCategory",
              state: `${category.name}`,
            }}
          >
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="h4 mb-0 font-weight-bold text-gray-800">
                    {category.name}
                  </div>
                  <hr className="sidebar-divider d-none d-md-block" />
                  <div className="text-center">
                    <img className="w-100 h-100" src={category.img} alt={category.alt} />
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
    )
}

export default CategoryItem