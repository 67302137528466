import React from "react";
import Products from "./Products";
import Us from "./Us";
import Contact from "./Contact";
import SearchProducts from "../components/SearchProducts";
import NotFound from "./NotFound";
import ProductsByCategory from "./ProductsByCategory";
import { Route, Switch } from "react-router-dom";

function Home() {
  return (
    <React.Fragment>
      <Switch>
        <Route exact path="/">
          <Products />
        </Route>
        <Route path="/Us">
          <Us />
        </Route>
        <Route path="/Contact">
          <Contact />
        </Route>
        <Route path="/SearchProducts">
          <SearchProducts />
        </Route>
        <Route path="/ProductsByCategory">
          <ProductsByCategory />
        </Route>
        <Route component={NotFound} />
      </Switch>
    </React.Fragment>
  );
}

export default Home;
