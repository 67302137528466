import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import all_products from '../data/products.json'
import { useState } from 'react';
import ProductItem from '../components/ProductItem';

function ProductsByCategory() {
    const location = useLocation()
    const category = location.state
    const [productsFiltered, setProductsFiltered] = useState([])
    const [searchInput, setSearchInput] = useState("")

    useEffect(() => {
        let productsFiltered = all_products.filter(product => product.epp === category)
        let productsSearched = productsFiltered.filter(product => {
            if(product.mod.toLowerCase().includes(searchInput.toLowerCase()) || product.epp.toLowerCase().includes(searchInput.toLowerCase())){
                return true
            }           
        }
        )
        setProductsFiltered(productsSearched)
    }, [category, searchInput])

    console.log(searchInput)

    return (
        <>

            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">Productos</a></li>
                    <li className="breadcrumb-item active" aria-current="page">{category}</li>
                </ol>
            </nav>
            <div className="d-flex justify-content-center">
                <input className="form-control w-75" type="search" placeholder="Buscar" aria-label="Search" name="search" id="search" onChange={(e) => setSearchInput(e.target.value)} />
            </div>
            {
                productsFiltered.length>0 ?
                    <div className=" d-flex justify-content-center flex-wrap ">
                        {
                            productsFiltered.map(product => (
                                <ProductItem key={product.cod} product={product} />
                            ))
                        }
                    </div>
                    :
                    <div className='vh-100 m-5 text-center' >No hay productos para mostrar</div>
            }

        </>
    )
}

export default ProductsByCategory;
