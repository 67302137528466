import React from 'react';

function Footer(){
    return (
        <React.Fragment>
			<footer className="footer sticky-footer col" style={{ backgroundColor:"#318CE7"}}>
				<div className="container-fluid">
					<div className='row justify-content-between'>
					
					<div className="col-xs-12 col-sm-3">
						<div className="copyright text-center my-auto">
							<span className="h6" style={{color: "white"}}>Copyright &copy; EPPINSUMOSARG</span>
						</div>
					</div>

					<div className="col-xs-12 col-sm-3">
						<div className="copyright text-center my-auto">
							<a href="https://instagram.com/eppinsumosarg" target="_blank" rel="noopener noreferrer">
								<i className="h6 fab fa-instagram" style={{color:"white"}}><span className="h6" style={{color: "white", fontFamily:"Nunito, -apple-system, BlinkMacSystemFont"}}> EPPINSUMOSARG</span></i>
							</a>
						</div>
					</div>

					</div>
				</div>
			</footer>

        </React.Fragment>
    )
}
export default Footer;