import React from "react";
import lentes from '../assets/images/lentesvision.webp';
import zapatos from '../assets/images/zapatosmision.jpg';


function Us() {
  return(
    <div className="container my-3">
      <div className="card-group mt-3">

        <div className="card border-left- shadow h-100 py-2 mx-3" style={{minHeight:"580px"}}>
            <div className="card-body">
                <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                        <div className="h4 mb-0 font-weight-bold text-gray-800">Mision</div>
                        <hr className="sidebar-divider d-none d-md-block"/> 
                        <div className="text-xs font-weight-bold text- text-uppercase mb-1">Nuestra filosofía es impulsada por el deseo de atraer y satisfacer la demanda de nuestros consumidores con artículos para el cuidado personal dentro de la industria, de tal manera garantizamos la seguridad industrial nuestros clientes.
Gracias a nuestras capacitaciones de trabajo en forma segura a las industrias garantizamos la idoneidad del personal en el desarrollo del trabajo en forma segura.</div>
                                <div className="text-center"><img className="w-100" src={zapatos} alt="eppinsumosarg"/></div>
                    </div>
                </div>
            </div>
        </div>

        <div className="card border-left- shadow h-100 py-2 mx-3" style={{minHeight:"580px"}}>
            <div className="card-body">
                <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                        <div className="h4 mb-0 font-weight-bold text-gray-800">Vision</div>
                        <hr className="sidebar-divider d-none d-md-block"/> 
                        <div className="text-xs font-weight-bold text- text-uppercase mb-1">
                            el enfoque hacia el cumplimiento de esta misión solo se compara con la pasión por alcanzar nuestros objetivos estrategicos
                            <ul>
                                <li>Ser líderes en los mercados donde participamos</li>
                                <li>Contribuir en la innovación de productos de seguridad</li>
                                <li>Fomentar la cultura de protección y cuidado personal</li>
                                <li>capacitar constantemente a todo personal industrial sobre las buenas prácticas y usos de EPP.</li>
                            </ul>
                            </div>
                                <div className="text-center"><img className="w-100" src={lentes} alt="eppinsumosarg"/></div>
                    </div>
                </div>
            </div>
        </div>

      </div>  
    </div>

    
    
)
}

export default Us;
