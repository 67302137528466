import React from "react";
import image from "../assets/images/guantes_gen.png";


const ProductItem = ({ product }) => {
    return (
        <>
        <div className="card col-12 col-lg-3 m-2">
            {/* <img className="w-100" src={image} alt="eppinsumosarg" /> */}
            <div className="card-body">
                
                <h4 className="card-title">{product.mod}</h4>
                <p className="card-text text-bold">{product.epp}, {product.tipo}</p>
                <p className="card-text">Talles: {product.talle}</p>
                <p className="card-text"><small className="text-body-secondary">{product.cod}</small></p>
                
            </div>
            <div className="card-footer btn bg-white text-center"><a href={`https://api.whatsapp.com/send?phone=+5491172347526&text=Hola!%20Vengo%20desde%20la%20p%C3%A1gina%20web%20para%20consultarte%20sobre%20el%20producto%20${product.cod},%20${product.mod}`}><i className="h6 fab fa-whatsapp text-success" style={{color:"white"}}><span >  Consultanos por whatsapp</span></i></a></div>
        </div>
        </>
    )
}

export default ProductItem