import React from 'react';
import NavBar from '../components/NavBar';
import Home from './Home';
import Footer from '../components/Footer';

function App() {
  return (
    <>
      <NavBar />
      <Home/>
      <Footer />
    </>
  );
}

export default App;
